import React from "react";
import Select from 'react-select';
import TextField from '@material-ui/core/TextField';
import { GreenButton } from "../styled_components/default";

class CompanyUsers extends React.Component {
  state = {
    allowedUsers: [],
    query: "",
    users: [],
    userEmail: '',
    roles: [
      {value: "admin", label: "Admin"},
      {value: "consultor", label: "Consultor"},
    ]
  };

  async fetchRoles() {
    let result = await axios.get(`/roles.json`);
    this.setState({roles: result.data.map((role)=> ({value: role.id, label: role.label})) })
  }


  async fetchUsers() {
    if(this.props.company){
      let result = await axios.get(`/companies/${this.props.company}/company_users.json`, {
        params: {
          query: this.state.query
        }
      });
      this.setState({allowedUsers: result.data })  
    }
  }

  async addAllowedUser() {
    if (!this.state.userEmail) {
      alert('Email do usuário é obrigatório');
      return;
    }

    try {
      let result = await axios.post(`/companies/${this.props.company}/company_users.json`, {
        user_email: this.state.userEmail,
        company_id: this.props.company,
        role_id: this.state.currentRole   
      });
  
      this.setState((oldState) => {
        oldState.allowedUsers = result.data;
        return { ...oldState };
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        if (confirm('O usuário não existe. Deseja cadastrar novo?')) {
          try {
            let result = await axios.post(`/companies/${this.props.company}/company_users.json`, {
              is_new: true,
              user_email: this.state.userEmail,
              company_id: this.props.company,
              role_id: this.state.currentRole
            });
        
            this.setState((oldState) => {
              oldState.allowedUsers = result.data;
              return { ...oldState };
            });
            
            alert('Usuário cadastrado com sucesso.')
          } catch (createError) {
            console.error('Ocorreu um erro ao criar o usuário:', createError);
            alert('Desculpe, não foi possível criar o usuário');
          }
        }
      } else {
        console.error('Ocorreu um erro ao incluir usuário:', error);
        alert('Desculpe, não foi possível incluir usuário');
      }
    }
  }

  async removeUser(user){
    
    let result = await axios.delete(`/companies/${this.props.company}/company_users/${user.user.id}.json`);

    this.setState((oldState)=>{
      oldState.allowedUsers = result.data
      return {...oldState}
    })
  }

  componentDidMount() {
    this.fetchRoles();
    this.fetchUsers();
  }

  render() {
    let { users, roles } = this.state;
    return (
      <div>
        <div style={{ display: "flex", alignItems: 'center' }}>
          <TextField
            label="Email do usuário"
            placeHolder="Informe o email completo do usuário"
            onChange={(event) => this.setState({ userEmail: event.target.value })}
            style={{ width: '100%' }}
          />
          <div style={{width: 200}} className={'margin-left-10'}>
            <Select
              options={roles}
              placeholder={"Permissão"}
              onChange={(role)=>{ this.setState({currentRole: role.value})}}
              TextFieldProps={{
                label: 'Regra de acesso',
                InputLabelProps: {
                  htmlFor: 'react-select-single',
                  shrink: true,
                },
              }}
            />  
          </div>
          <GreenButton className={'margin-left-10'} onClick={this.addAllowedUser.bind(this)}>ADICIONAR</GreenButton>
        </div>
        <table className={'table'}>
          {this.state.allowedUsers.map((user)=>(
            <tr>
              <td>
                {user.user.name}  
              </td>
              <td>
                {user.user.email}  
              </td>
              <td>
                {user.role.label}  
              </td>
              <td>
                <i onClick={()=> this.removeUser(user)} className={'fa fa-close'}/>
              </td>
            </tr>
          ))}
        </table>
      </div>
    );
  }
}

export default CompanyUsers;
