import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import LoadingDialog from '../LoadingDialog'
import {
  TextInput,
  Select,
  Label,
  GreenButton,
} from "../../styled_components/default.js";

class FormRequestErps extends Component {
  constructor(props) {
    super(props);
    this.requestsCounter = 0;
    this.state = {
      linhas: [
        { tipo: '', dataInicio: '', dataFim: '' }
      ],
    };
  }

  adicionarLinha = () => {
    if (this.state.linhas.length < this.props.maxLinhas) {
      this.setState((prevState) => ({
        linhas: [...prevState.linhas, { tipo: '', dataInicio: '', dataFim: '' }]
      }));
    }
  };

  removerLinha = (index) => {
    this.setState((prevState) => ({
      linhas: prevState.linhas.filter((_, i) => i !== index)
    }));
  };

  handleTipoChange = (index, tipo) => {
    this.setState((prevState) => ({
      linhas: prevState.linhas.map((linha, i) =>
        i === index ? { ...linha, tipo } : linha
      )
    }));
  };

  handleDataChange = (index, field, value) => {
    this.setState((prevState) => ({
      linhas: prevState.linhas.map((linha, i) =>
        i === index ? { ...linha, [field]: value } : linha
      )
    }));
  };

  tiposRestantes = (index) => {
    const tiposDisponiveis = [
      { value: 'pagar', label: 'A pagar' },
      { value: 'receber', label: 'A receber' },
      { value: 'pago', label: 'Pagos' },
      { value: 'recebido', label: 'Recebidos' },
    ];
    const tiposSelecionados = this.state.linhas.map(linha => linha.tipo).filter(Boolean);

    return tiposDisponiveis.filter(
      tipo => tipo.value === this.state.linhas[index].tipo || !tiposSelecionados.includes(tipo.value)
    );
  };

  handleSubmit = async (e) => {
    e.preventDefault();
  
    const { initialDate } = this.props;
    const { linhas } = this.state;
  
    const tiposDisponiveis = [
      { value: 'pagar', label: 'A pagar' },
      { value: 'receber', label: 'A receber' },
      { value: 'pago', label: 'Pagos' },
      { value: 'recebido', label: 'Recebidos' },
    ];
  
    const invalidLine = linhas.find((linha) => {
      if (linha.dataInicio && linha.dataInicio < initialDate) {
        return true;
      }
      return false;
    });
  
    if (invalidLine) {
      const tipoSelecionado = tiposDisponiveis.find(tipo => tipo.value === invalidLine.tipo)?.label.toUpperCase();
      const formattedInitialDate = new Date(initialDate + 'T00:00:00').toLocaleDateString('pt-BR');
      alert(
        `No tipo ${tipoSelecionado}, a data início não pode ser menor que ${formattedInitialDate}, que é a data de início da empresa configurada no sistema.`
      );
      return;
    }

    this.setState({loading: true})
    this.requestsCounter += 1;

    try {
      await axios
        .post('/integrations/send_request_erps.json', {
          requests: linhas,
        })
        .then((result) => {
          if (result.status === 200) {
            alert('Solicitação enviada com sucesso. Os dados estão sendo processados!');
            Turbolinks.visit(`/`);
          } else {
            alert('Não foi possível enviar solicitação.');
          }
        });
    } catch (error) {
      console.error('Erro:', error);
      alert('Erro ao enviar os dados.');
    }

    this.requestsCounter -= 1;
    this.setState({loading: false})
  };

  render() {
    return (
      <React.Fragment>
        <form onSubmit={this.handleSubmit}>
          <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
            <div style={{ flex: '0 0 16.4%' }} className="margin-left-10">
              <Label style={{ width: '100%', height: '100%' }}>TIPO DE ARQUIVO</Label>
            </div>
            <div>
              <Label style={{ width: '100%', height: '100%' }}>SELECIONE O PERÍODO</Label>
            </div>
          </div>
  
          {this.state.linhas.map((linha, index) => (
            <div key={index} className="linha-tipo-arquivo">
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                <div style={{ flex: '0 0 15%' }} className="margin-left-10">
                  <Select
                    value={linha.tipo}
                    onChange={(e) => this.handleTipoChange(index, e.target.value)}
                    required
                  >
                    <option value="">Selecione</option>
                    {this.tiposRestantes(index).map(tipo => (
                      <option key={tipo.value} value={tipo.value}>
                        {tipo.label}
                      </option>
                    ))}
                  </Select>
                </div>
                
                <div style={{ flex: '0 0 25%' }} className="margin-left-20">
                  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                    <TextInput
                      type="date"
                      value={linha.dataInicio}
                      onChange={(e) => this.handleDataChange(index, 'dataInicio', e.target.value)}
                      required
                      style={{ flex: 1 }}
                    />
                    <span style={{ paddingBottom: '17px' }}>a</span>
                    <TextInput
                      type="date"
                      value={linha.dataFim}
                      onChange={(e) => this.handleDataChange(index, 'dataFim', e.target.value)}
                      required
                      style={{ flex: 1 }}
                    />
                  </div>
                </div>
                {index > 0 && (
                  <Button type="button" onClick={() => this.removerLinha(index)} style={{ marginBottom: '17px' }}>
                    <i style={{ fontSize: 15 }} className="fa fa-trash" />
                  </Button>
                )}
              </div>
            </div>
          ))}
          <div className="margin-left-10">
            <Button type="button" onClick={this.adicionarLinha} disabled={this.state.linhas.length >= this.props.maxLinhas}>
              <i className={"fa fa-plus"} style={{ marginRight: 5 }} /> INSERIR OUTRO TIPO DE ARQUIVO
            </Button>
          
            <GreenButton type="submit" color="success" className="margin-left-10">INICIAR ATUALIZAÇÃO</GreenButton>
          </div>
        </form>
        
        <LoadingDialog open={this.requestsCounter > 0}/>
      </React.Fragment>
    );
  }
}

export default FormRequestErps;
